<template id="ownerinfo">
  <v-ons-page id="ownerinfoPage">
    <v-ons-card>
      <div v-if="hasData">
        <div class="content">
          <div class="profile-card" style="background-color:black;color:white;">
            <div
              class="profile-name"
              style="background-color:black;color:white;"
            >
              <span @click="actionSheetVisible = true">{{ FullName1 }}</span>
            </div>
            <div class="profile-name" v-if="FullName2 != ''">
              <span @click="actionSheetVisible = true">{{ FullName2 }}</span>
            </div>
            <div class="profile-name" v-if="Company != ''">
              <span @click="actionSheetVisible = true">{{ Company }}</span>
            </div>
            <div
              v-if="Address != ''"
              style="text-align: left; margin: 10px 0 0 15px; font-size:14px;color:white;"
            >
              {{ Address }}
            </div>
            <v-ons-list style="background-color:black;color:white;">
              <v-ons-list-item
                expandable
                style="background-color:black;color:white;font-style:italic"
                >more details...
                <div class="expandable-content">
                  <div
                    v-if="OfficeTel != ''"
                    style="text-align: left; margin: 10px 0 0 10px; font-size:14px;color:white;font-style:normal "
                  >
                    <v-ons-icon
                      icon="md-phone"
                      style="color: blue;"
                    ></v-ons-icon>
                    Office: {{ OfficeTel }}
                  </div>
                  <div
                    v-if="MobileTel != ''"
                    style="text-align: left; margin: 10px 0 0 10px; font-size:14px;color:white;font-style:normal "
                  >
                    <i class="zmdi zmdi-phone" style="color: blue;"></i>
                    Mobile: {{ MobileTel }}
                  </div>
                  <div
                    v-if="HomeTel != ''"
                    style="text-align: left; margin: 10px 0 0 10px; font-size:14px;color:white;font-style:normal "
                  >
                    <i class="zmdi zmdi-phone" style="color: blue;"></i>
                    Home: {{ HomeTel }}
                  </div>
                  <div
                    v-if="Email1 != ''"
                    style="text-align: left;margin: 10px 0 0  10px; font-size:14px;color:white;font-style:normal "
                  >
                    <i class="zmdi zmdi-email" style="color: blue;"></i>
                    Email: {{ Email1 }}
                  </div>
                  <div
                    v-if="Email2 != ''"
                    style="text-align: left;margin: 10px 0 0  10px; font-size:14px;color:white;font-style:normal "
                  >
                    <i class="zmdi zmdi-email" style="color: blue;"></i>
                    Email: {{ Email2 }}
                  </div>
                </div>
              </v-ons-list-item>
            </v-ons-list>
          </div>

          <v-ons-list>
            <v-ons-list-item expandable
              >Summary
              <v-ons-row style="height:100px;" vertical-align="center">
                <v-ons-col class="info-col" vertical-align="center" width="50%">
                  <div>
                    <img
                      src="../assets/img/property1.png"
                      alt="No Of Properties"
                      style="width:30px;height:30px;"
                    />
                  </div>
                  <div class="info-num">{{ NoOfProp }}</div>
                  <div class="info-unit">Properties</div>
                </v-ons-col>
                <v-ons-col class="info-col" vertical-align="center" width="50%">
                  <div>
                    <img
                      src="../assets/img/portfolio1.png"
                      alt="Total Portfolio"
                      style="width:30px;height:30px;"
                    />
                  </div>
                  <div class="info-num currency">{{ TotalPortfolio }}</div>
                  <div class="info-unit">Portfolio Value</div>
                </v-ons-col>
              </v-ons-row>
              <div class="expandable-content">
                <BasicTableApp />
              </div>
            </v-ons-list-item>
          </v-ons-list>
          <v-ons-list>
            <v-ons-list-item expandable>
              PROFILE
              <div class="expandable-content">
                <div>Contact ID: {{ ContactID }}</div>
                <div>Relationship: {{ Relationship }}</div>
                <div>Market Position: {{ MarketPosition }}</div>
                <div>Investment Range: {{ InvestmentRange }}</div>
                <div>Type: {{ Type }}</div>
              </div>
            </v-ons-list-item>
          </v-ons-list>
          <v-ons-list>
            <v-ons-list-item>
              <v-ons-row style="height:100px;" vertical-align="center">
                <v-ons-col class="info-col" vertical-align="center" width="50%"
                  ><p>LAST RESULT:</p>
                  <p>{{ LastResult }}</p></v-ons-col
                >
                <v-ons-col class="info-col" vertical-align="center" width="50%"
                  ><p>NEXT ACTION:</p>
                  <p>{{ NextAction }}</p></v-ons-col
                >
              </v-ons-row>
            </v-ons-list-item>
          </v-ons-list>
        </div>
      </div>
      <div
        v-else
        style="text-align: center; font-size: 20px; margin-top: 20px; "
      >
        Owner information is coming...
      </div>
    </v-ons-card>

    <v-ons-action-sheet :visible.sync="actionSheetVisible" cancelable>
      <v-ons-action-sheet-button icon="md-phone" v-show="(OfficeTel)"
        ><a :href="(OfficeTel) ? 'tel://' + OfficeTel : 'javascript:'">voice call</a></v-ons-action-sheet-button
      >
      <v-ons-action-sheet-button icon="md-email" v-show="(Email1)"
        ><a :href="(Email1) ? 'mailto:' + Email1 : 'javascript:'">send email</a></v-ons-action-sheet-button
      >
      <v-ons-action-sheet-button
        v-show="this.FullName1"
        icon="md-search"
        @click="goToSearch2FN1"
        >search {{ FullName1 }}
      </v-ons-action-sheet-button>
      <v-ons-action-sheet-button
        v-show="this.FullName2"
        icon="md-search"
        @click="goToSearch2FN2"
        >search {{ FullName2 }}
      </v-ons-action-sheet-button>
      <v-ons-action-sheet-button
        v-show="this.Company"
        icon="md-search"
        @click="goToSearch2CY"
        >search {{ Company }}</v-ons-action-sheet-button
      >
    </v-ons-action-sheet>
  </v-ons-page>
</template>

<script>
// Import the EventBus we just created.
import EventBus from '../config/event-bus';
import router from "../router";
import BasicTableApp from "../components/portfoliotv";

//for API call
import axios from "axios";

const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)

export default {
  data: function() {
    return {
      FullName1: "",
      FullName2: "",
      Company: "",
      Address: "",
      HomeTel: "",
      OfficeTel: "",
      MobileTel: "",
      Email1: "",
      Email2: "",
      NoOfProp: 0,
      TotalPortfolio: 0,
      LastResult: "",
      NextAction: "",
      ContactID: "",
      Relationship: "",
      InvestmentRange: "",
      MarketPosition: "",
      Type: "",
      hasData: false,
      actionSheetVisible: false,
      router: router,
    };
  },
  mounted() {
    EventBus.$on("get_db-contactid", (data) => {
      console.log("Get db contactid event in ownerinfo: ", data);
      this.getPortfolioRows(data);
    }),
      EventBus.$on("get_owner", (data) => {
        console.log("Get owner event in ownerinfo: ");

        if (data !== null) {
          this.FullName1 = (data.FirstName1 + " " + data.LastName1).trim();
          this.FullName2 = (data.FirstName2 + " " + data.LastName2).trim();
          this.Company = data.Company.trim();
          this.Address = data.Address.trim();
          this.HomeTel = data.HomeTel.trim();
          this.OfficeTel = data.ContactNo.trim();
          this.MobileTel = data.MobileTel.trim();
          this.Email1 = data.Email_key3.trim();
          this.Email2 = data.Email.trim();
          this.NoOfProp = data.NoOfProperties.trim();
          this.TotalPortfolio = data.TotalPortfolio.trim();
          this.LastResult = data.LastResult.trim();
          this.NextAction = data.NextAction.trim();
          this.ContactID = data.ContactID.trim();
          this.Relationship = data.Relationship.trim();
          this.InvestmentRange = data.InvestmentRange.trim();
          this.MarketPosition = data.MarketPosition.trim();
          this.Type = data.Type.trim();
          this.hasData = true;
        } else this.hasData = false;
      });
  }, // end created
  methods: {
    async getPortfolioRows(db_contactid) {
      var acontactid = db_contactid.split("-")[1];
      var adb = db_contactid.split("-")[0];
      // acontactid=5313
      // adb='apt'
      var dbURL =
        "https://us-central1-terratonepull-c669d.cloudfunctions.net/PortfolioTreeView?database=" +
        adb +
        "&contactID=" +
        acontactid;
      let that = this;
      await axios.get(proxyurl1 + dbURL).then(function(response) {
        console.log('portfolio:',response.data[0]);
        EventBus.$emit("portfolio", response.data[0]);
      });
    },
    goToSearch() {
      router.push({
        path: "/search",
        name: "search",
        params: { strSearchFromOwnerInfo: this.Company },
      });
    },
    goToSearch2FN1() {
      this.actionSheetVisible = false;
      EventBus.$emit("SearchFrmOInfo", this.FullName1);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
    goToSearch2FN2() {
      this.actionSheetVisible = false;
      EventBus.$emit("SearchFrmOInfo", this.FullName2);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
    goToSearch2CY() {
      this.actionSheetVisible = false;
      EventBus.$emit("SearchFrmOInfo", this.Company);
      document.querySelector("ons-tabbar").setActiveTab(5);
    },
  },
  components: {
    BasicTableApp: BasicTableApp,
  },
  computed: {
    hasOwnerInfo() {
      //return (this.hasData)
      return true;
    },
  },
};
</script>
<style scoped>
.profile-card {
  width: 100%;
  text-align: left;
  color: black;
  padding: 10px 0;
  line-height: 1.4;
  background-color: white;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 1px 0px;
}

.profile-image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: black;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 0px 0px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 0px 0px;
}

.profile-name {
  margin: 5px 5px 5px 15px;
  font-weight: 200;
  font-size: 18px;
}

.profile-id {
  margin: 0 0 5px 0;
  font-size: 14px;
  opacity: 0.6;
}

.profile-desc {
  font-size: 15px;
  opacity: 0.6;
  width: 90%;
  text-align: center;
  margin: 0 auto;
}

.profile-pic {
  width: 50px;
  background-color: #fff;
  margin: 20px auto 10px;
  border: 1px solid #999;
  border-radius: 4px;
}

.profile-pic > img {
  display: block;
  max-width: 100%;
}

.info-col {
  height: 100px;
  line-height: 1;
  padding: 12px 0 12px 4px;
}

.info-num {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.8;
}

.info-unit {
  margin-top: 6px;
  font-size: 12px;
  opacity: 0.4;
}

.currency:before {
  content: "$";
}
/* .currency:after{ content: '.00'; } */

.search-bar {
  width: 100%;
  /*position: fixed;*/
  display: block;
  margin-top: 2px;
  margin-bottom: 2px;
}

/* Style buttons */
.btn {
  background-color: transparent; /* Blue background */
  border: none; /* Remove borders */
  color: blue; /* White text */
  padding: 5px 5px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}

/* Darker background on mouse-over  */
.btn:hover {
  color: red; /* White text */
}
</style>
