/*************************/
/* Land Merging Function */
/* Version 2.1           */
/*************************/

import union from "@turf/union";
import booleanEqual from "@turf/boolean-equal";
import * as turfHelpers from "@turf/helpers";
import firebase from "./firebase";

class LandMerger {
    constructor() {
        this.isMultipleSelectionOn = false;
        this.docIds = [];
        this.lastVertices = [];
        this.mergedPIDs = [];
    }

    addButtonTo(parentElementId) {
        this.landGroupingBtn = document.createElement("ons-speed-dial-item");
        this.unselectedBtnColor = this.landGroupingBtn.style.backgroundColor;
        this.selectedBtnColor = "red";
        let landGroupingIcon = document.createElement("ons-icon");
        landGroupingIcon.setAttribute("icon", "md-ungroup");
        landGroupingIcon.landMergerObj = this;
        firebase.usersCollection.doc(firebase.auth.currentUser.uid).get()
            .then((doc) => {
                if (doc.exists && doc.data().allowMergeLands) {
                    landGroupingIcon.addEventListener("mousedown", this.pressIcon);
                    landGroupingIcon.addEventListener("mouseup", this.releaseIcon);
                    landGroupingIcon.addEventListener("touchstart", this.pressIcon);
                    landGroupingIcon.addEventListener("touchend", this.releaseIcon);
                }
            })
            .catch((error) => {
                console.log("Error in reading user's info:", error);
            });
        landGroupingIcon.onclick = () => this.toggleMultipleSelection();
        this.landGroupingBtn.appendChild(landGroupingIcon);
        document.getElementById(parentElementId).appendChild(this.landGroupingBtn);
    }

    pressIcon() {
        let that = this.landMergerObj;  // "this" is the icon element
        if (that.mergedPIDs.length <= 1)
            return;
        this.iconPressed = true; 
        setTimeout(() => {
            if (this.iconPressed) {
                this.iconPressed = false;
                if (that.docIds.length > 1) {
                    if (confirm("MERGE Confirmation:\nDo you want to perminantly MERGE the selected lands?")) {
                        that.saveResult();
                    }
                } else {
                    if (confirm("DELETE Confirmation:\nDo you want to perminantly UNMERGE the selected land?")) {
                        that.deleteResult();
                    }
                }
            }
        }, 1000);
    }

    releaseIcon() {
        this.iconPressed = false;  // "this" is the icon element
    }

    toggleMultipleSelection() {
        this.isMultipleSelectionOn = !this.isMultipleSelectionOn;
        if (this.isMultipleSelectionOn) {
            this.landGroupingBtn.style.backgroundColor = this.selectedBtnColor;
        } else {
            this.landGroupingBtn.style.backgroundColor = this.unselectedBtnColor;
        }
    }
    
    storePolygon(vertices) {
        // Stores a sallow copy of the vertices array in storedVertices
        this.lastVertices = [];
        for (let v of vertices) {
            this.lastVertices.push(v.slice());
        }
    }

    getVertices(newVertices, PID, callback) {
        firebase.mergedlandsCollection.where("PIDs", "array-contains", PID).limit(1).get()
            .then((snapshot) => {
                let docId = null;
                let PIDs = [PID];
                let vertices;
                snapshot.forEach((doc) => {
                    docId = doc.id;
                    PIDs = doc.data().PIDs;
                    let x = doc.data().x;
                    let y = doc.data().y;
                    vertices = [];
                    for (let i = 0; i < x.length; ++i) {
                        vertices.push([x[i], y[i]]);
                    }                    
                });
                if (vertices) {
                    newVertices = [vertices];
                }

                if (newVertices == null || newVertices.length == 0) {
                    callback(storedVertices);
                    return;
                }

                if (this.isMultipleSelectionOn && this.lastVertices.length > 0) {
                    let prevPoly = turfHelpers.polygon(this.lastVertices);
                    let newPoly = turfHelpers.polygon(newVertices);
                    let resultPoly = union(prevPoly, newPoly);
                    if (resultPoly.geometry.type == "Polygon") {
                        if (!booleanEqual(prevPoly, resultPoly)) {
                            this.storePolygon(resultPoly.geometry.coordinates);
                            this.mergedPIDs = this.mergedPIDs.concat(PIDs);
                            this.docIds.push(docId);  // if there is no docId, a null is pushed into docIds
                        }
                        callback(resultPoly.geometry.coordinates);
                        return;
                    }
                }
                this.storePolygon(newVertices);
                this.mergedPIDs = PIDs;
                this.docIds = [docId];
                callback(newVertices);
            })
            .catch((error) => {
                console.log("ERROR in getting merged-lands data:", error);
            });
    }

    saveResult() {
        if (this.lastVertices.length == 0)
            return;
        
        let x = [];
        let y = [];
        for (let coordinates of this.lastVertices[0]) {
            x.push(coordinates[0]);
            y.push(coordinates[1]);
        }

        let batch = firebase.db.batch();
        for (let docId of this.docIds) {
            if (docId) {
                batch.delete(firebase.mergedlandsCollection.doc(docId));
            }
        }
        let newDocRef = firebase.mergedlandsCollection.doc();
        batch.set(newDocRef, {
            createdOn: Date(),
            createdBy: firebase.auth.currentUser.uid,
            PIDs: this.mergedPIDs,
            x: x,
            y: y,
        });

        batch.commit().then(() => {
            this.docIds = [newDocRef.id];
            alert("The merged land is saved.");
        }).catch((error) => {
            alert("Error in saving the merged land.\n" + error);
        });
    }
    
    deleteResult() {
        let batch = firebase.db.batch();
        for (let docId of this.docIds) {  // there should only be one element in this.docIds
            if (docId) {
                batch.delete(firebase.mergedlandsCollection.doc(docId));
            }
        }
        batch.commit().then(() => {
            this.docIds = [];
            for (let i = 0; i < this.mergedPIDs.length; ++i) {
                this.docIds.push(null);
            }
            alert("The lands are perminantly UNMERGED in database.");
        }).catch((error) => {
            alert("Error in unmerging the lands in database.\n" + error);
        });
    }
}

export default new LandMerger();