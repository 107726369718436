<template id="RealnetReport">
  <v-ons-page id="RealnetReportPage">

    <!-- Top Toolbar -->
    <ons-toolbar  modifier="transparent" inline>
      <div class="left">
        <ons-if platform="android other"><ons-back-button v-on:click="goBack">Back</ons-back-button></ons-if>
      </div>
      <div class="center">
        <ons-if platform="ios">
          <!--ons-toolbar-button>
            <ons-icon icon="md-refresh" onclick="
              if (SearchList.length>0){showTransactionListReport_map(SearchList)};document.getElementById('carousel').setActiveIndex(0);">
            </ons-icon>
          </ons-toolbar-button-->
        </ons-if>
      </div>
      <div class="right">
        <ons-if platform="ios">
          <ons-toolbar-button v-on:click="goBack"><ons-icon style="opacity: 0.7; color: black" icon="ion-ios-close"></ons-icon></ons-toolbar-button>
        </ons-if>
        <ons-if platform="android other">
          <!--ons-toolbar-button >
            <ons-icon icon="md-refresh" onclick="
              if (SearchList.length>0){showTransactionListReport_map(SearchList)};document.getElementById('carousel').setActiveIndex(0);">
            </ons-icon>
          </ons-toolbar-button-->
        </ons-if>
      </div>
    </ons-toolbar>

    <!-- Single Transaction Report -->
    <div v-if="dataIndex!=-1">
      <ons-card>
        <table>
          <tr>
            <td class="detail-label">Date</td>
            <td class="detail-value">{{getDate(getInfo("EventDate"))}}</td>
          </tr>
          <tr>
            <td class="detail-label">Sale Type</td>
            <td class="detail-value">{{getInfo("SaleType")}}</td>
          </tr>
          <tr>
            <td class="detail-label">Price</td>
            <td class="detail-value">{{formatPrice(getInfo("Price"))}}</td>
          </tr>
          <tr>
            <td class="detail-label">Capitalization</td>
            <td class="detail-value">{{formatNumber(getInfo("CapitalizationRate") * 100, 100, "", "%")}}</td>
          </tr>
        </table>

        <div class="trans-subheading">General Information</div>
        <table>
          <tr>
            <td class="detail-label">PID(s)</td>
            <td class="detail-value">{{getInfo("PIDCode")}}<span v-if="getInfo('PIDCodes')!=null">, {{getInfo("PIDCodes")}}</span></td>
          </tr>
          <tr>
            <td class="detail-label">
              <span v-if="addresses.length==1">Address</span>
              <span v-else>Addresses</span>
            </td>
            <td class="detail-value">
              <ul style="list-style-type:none;">
                <li v-for="address in addresses" :key="address">{{address}}</li>
              </ul>  
            </td>
          </tr>
          <tr>
            <td class="detail-label">Municipality</td>
            <td class="detail-value">{{getInfo("Municipality")}}</td>
          </tr>
          <tr>
            <td class="detail-label">Asset Type</td>
            <td class="detail-value">{{assetTypes[getInfo("AssetType")]}}</td>
          </tr>
          <tr>
            <td class="detail-label">Construction Year</td>
            <td class="detail-value">{{formatNumber(getInfo("ConstructionYear"))}}</td>
          </tr>
        </table>

        <div class="trans-subheading">Property Size</div>
        <table v-if="getInfo('AssetType')=='APT'">
          <tr>
            <td class="detail-label">Site Size</td>
            <td class="detail-value">{{formatNumber(lotSize, 0, "", lotSizeUnit)}}</td>
          </tr>
          <tr>
            <td class="detail-label"># of Units</td>
            <td class="detail-value">{{formatNumber(getInfo("TotalUnits"), 0)}}</td>
          </tr>
          <tr v-if="getInfo().TotalUnits != 0">
            <td class="detail-label"></td>
            <td class="detail-value">{{formatPrice(getInfo("Price") / getInfo("TotalUnits"), "", " / unit")}}</td>
          </tr>
        </table>
        <table v-else-if="getInfo('AssetType')=='ICI' || getInfo('AssetType')=='RLN'">
          <tr>
            <td class="detail-label">Site Size</td>
            <td class="detail-value">{{formatNumber(lotSize, 0, "", lotSizeUnit)}}</td>
          </tr>
          <tr>
            <td class="detail-label">FSR</td>
            <td class="detail-value">{{formatNumber(buildableArea / lotSize, 100)}}</td>
          </tr>
          <tr>
            <td class="detail-label">Buildable Area</td>
            <td class="detail-value">{{formatNumber(buildableArea, 0, "", " sq.ft")}}</td>
          </tr>
          <tr v-if="getInfo('PricePerSqFtBuildable') != 0">
            <td class="detail-label"></td>
            <td class="detail-value">{{formatPrice(getInfo("PricePerSqFtBuildable"), "", " / bsf")}}</td>
          </tr>
        </table>
        <table v-else-if="getInfo('AssetType')=='IND' || getInfo('AssetType')=='OFF' || getInfo('AssetType')=='RET'">
          <tr>
            <td class="detail-label">Site Size</td>
            <td class="detail-value">{{formatNumber(lotSize, 0, "", lotSizeUnit)}}</td>
          </tr>
          <tr>
            <td class="detail-label">Gross Leasable Area</td>
            <td class="detail-value">{{formatNumber(getInfo('BuildingSize'), 0, "", " sq.ft")}}</td>
          </tr>
          <tr v-if="getInfo('BuildingSize') != 0">
            <td class="detail-label"></td>
            <td class="detail-value">{{formatPrice(getInfo("BuildingSize") / getInfo("BuildingSize"), "", " / sq.ft")}}</td>
          </tr>
        </table>
        <table v-else>
          <tr>
            <td class="detail-label">Site Size</td>
            <td class="detail-value">{{formatNumber(lotSize, 0, "", lotSizeUnit)}}</td>
          </tr>
        </table>

        <div class="trans-subheading">Assessment Values {{formatNumber(getInfo("AssessmentYear"), -1, "(Year ", ")")}}</div>
        <table>
          <tr>
            <td class="detail-label">Land</td>
            <td class="detail-value assessment-value">{{formatPrice(getInfo("LandAssessment"))}}</td>
          </tr>
          <tr>
            <td class="detail-label">Building</td>
            <td class="detail-value assessment-value">{{formatPrice(getInfo("BuildingAssessment"))}}</td>
          </tr>
          <tr>
            <td class="detail-label">Total</td>
            <td class="detail-value assessment-value">{{formatPrice(getInfo("LandAssessment") + getInfo("BuildingAssessment"))}}</td>
          </tr>
        </table>
      
        <div class="trans-subheading">Vendor Information</div>
        <table>
          <tr>
            <td class="detail-label">Name</td>
            <td class="detail-value">{{getInfo("VendorName")}}</td>
          </tr>
          <tr>
            <td class="detail-label">Signing Officer</td>
            <td class="detail-value">{{getInfo("VendorSigningOfficer")}}</td>
          </tr>
          <tr>
            <td class="detail-label">Address</td>
            <td class="detail-value">{{getInfo("VendorAddress")}}</td>
          </tr>
        </table>

        <div class="trans-subheading">Purchaser Information</div>
        <table>
          <tr>
            <td class="detail-label">Name</td>
            <td class="detail-value">{{getInfo("PurchaserName")}}</td>
          </tr>
          <tr>
            <td class="detail-label">Signing Officer</td>
            <td class="detail-value">{{getInfo("PurchaserSigningOfficer")}}</td>
          </tr>
          <tr>
            <td class="detail-label">Address</td>
            <td class="detail-value">{{getInfo("PurchaserAddress")}}</td>
          </tr>
        </table>

        <div class="trans-subheading">Broker Information</div>
        <tr>
          <td class="detail-label">Brokers</td>
          <td class="detail-value">{{getInfo("Brokers", "-")}}</td>
        </tr>
      
        <div class="trans-subheading">Land Use Details</div>
        <div class="detail-value">{{getInfo("LandUseDetails", "-")}}</div>

        <div class="trans-subheading">General Remarks</div>
        <div class="detail-value">{{getInfo("GeneralRemarks", "-")}}</div>
      </ons-card>
    </div>

    <!-- Multiple Transactions Selection Page -->
    <div v-else>
      <p>Transactions Count: {{transactions.length}}</p>
      <p style="color:red" v-show="transactions.length > transactionsCount">
        Only the first {{transactionsCount}} transactions are shown below.
      </p>
      <ons-card v-for="i in transactionsCount" v-bind:key="i" v-on:click="selectTransaction(i-1)">
        <strong>
          Transaction Date: {{getDate(transactions[i-1].EventDate)}}<br>
          {{transactions[i-1].Address.split("\n")[0]}}<br>
        </strong>
        Asset Type: {{assetTypes[transactions[i-1].AssetType]}}<br>
        Sale Type: {{transactions[i-1].SaleType}}
      </ons-card>
    </div>
  </v-ons-page>
</template>


<script>
import EventBus from '../config/event-bus';

export default {
  data: function(){
    return {
      transactionsCountLimit: 50,
      transactions: [],
      transactionsCount: 0,
      dataIndex: -1,
      addresses: [],
      lotSize: 0,
      lotSizeUnit: "",
      buildableArea: 0,
      carousel: null,
      carouselIndex: 0,
      assetTypes: {
        "HOT": "Hotel",
        "ICI": "ICI Land",
        "IND": "Industrial",
        "APT": "Multi-Family",
        "OFF": "Office",
        "RET": "Retail",
        "RLN": "Residential Land"
        //"RLT": "Residential Lots"
      }
    };
  },
  created() {
    EventBus.$on("get_transactions", (data) => {
      //console.log("Get transactions: ", data);
      this.transactions = data;
      this.transactionsCount = this.transactions.length > this.transactionsCountLimit ?
        this.transactionsCountLimit : this.transactions.length;
      if (this.transactionsCount == 1) {
        this.setTransData(0);
      } else {
        this.dataIndex = -1;
      }
      this.carouselIndex = 0;
    });
  },

  mounted() {
    console.log("MOUNTING");
    EventBus.$emit("realnetReportMounted");
  },

  methods:{
    goBack() {
        if (this.dataIndex == -1 || this.transactions.length == 1) {
          this.$router.back();
        } else {
          this.dataIndex = -1;
        }
    },
    setTransData(index) {
      this.dataIndex = index;
      var data = this.getInfo();
      if (data != null) {
        this.addresses = data.Address.split("\n");
        this.lotSize = data.LotArea == null ? 0 : parseFloat(data.LotArea);
        if (this.lotSize != 0) {
          var lotSizeUnitPos = data.LotArea.indexOf(" ");
          this.lotSizeUnit = lotSizeUnitPos == -1 ? "" : data.LotArea.slice(lotSizeUnitPos);
          if (this.lotSizeUnit == " acre" || this.lotSizeUnit == " acres") {
            this.lotSize = Math.round(this.lotSize * 43560);
            this.lotSizeUnit = " sq.ft"
          }
        }
        this.buildableArea = data.Price / data.PricePerSqFtBuildable;
      }
    },
    selectTransaction(index) {
      this.setTransData(index);
    },
    getInfo(field = null, nullValue = null) {
      if (this.dataIndex < 0 || this.dataIndex > this.transactions.length|| this.transactions[this.dataIndex] == null) {
        return nullValue;
      }
      if (field == null) {
        return this.transactions[this.dataIndex];
      } else {
        return this.transactions[this.dataIndex][field];
      }
    },
    getDate(dt) {
      if (dt == null) {
        return "-";
      }
      return dt.split("T")[0];
    },
    getAssetType() {
      var assetCode = this.getInfo("AssetType");
      if (assetCode == null) {
        return "-"
      }
      return this.assetTypes[assetCode];
    },
    formatPrice(price, prefix="", suffix="", showPosSign=false) {
      if (price == null || price==Infinity) return "-";
      var sign = price < 0 ? "-" : (showPosSign ? "+" : "");
      return prefix + sign + "$" + Math.abs(Math.round(price)).toLocaleString("en-CA") + suffix;
    },
    formatNumber(n, digits=-1, prefix="", suffix="", showZero=false, showSign=false) {
      if (n==null || (n==0 && !showZero) || n==Infinity) return "-";
      if (digits > 0) n = Math.round(n * digits) / digits;
      var nStr = digits == -1 ? n : n.toLocaleString("en-CA");
      return prefix + ((showSign && n > 0) ? "+" : "") + nStr + suffix;
    }
  }
}

</script>

<style>
  .trans-subheading {
    text-align: left;
    background-color: #ccc;
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 3px;
    padding-left: 5px;
    font-weight: bold;
  }
  .detail-label {
    color: #070770;
    font-weight: bold;
    padding-top: 3px;
    padding-left: 5px;
    vertical-align: top;
    width: 170px;
  }
  .detail-value {
    padding-top:3px;
  }
  .assessment-value {
    width: 90px;
    text-align: right;
  }
</style>

